import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import { getSettingsValues } from '@wix/tpa-settings';
import { Service } from '@wix/ambassador-services-catalog-server/types';
import { Service as ServiceV2 } from '@wix/ambassador-bookings-services-v2-service/types';
import { CourseAvailabilityDisplay } from '../../service-page-view-model/details-section-view-model/detailsSectionViewModel';
import { SERVICE_PAGE_NAME } from './constants';
import { shouldRedirectToCalendarPage } from './controller-logic/ooi-migration';
import settingsParams from './settingsParams';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import {
  getServiceSlug,
  isBookingCalendarInstalled as isBookingCalendarInstalledUtils,
  isBookingFormInstalled as isBookingFormInstalledUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { initializeWidget } from './controller-logic/init-widgets';
import { dummySchedulingViewModel } from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { dummyViewModelFactory } from '../../service-page-view-model/servicePageViewModel';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const setProps = flowAPI.controllerConfig.setProps;
  // FIXME: Yoshi missing translations issue workaround.
  // https://wix.slack.com/archives/CAL591CDV/p1652941335953659
  try {
    await flowAPI.translations.init();
  } catch {}

  const t = flowAPI.translations.t;
  const { controllerConfig, experiments } = flowAPI;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, settingsParams, {
    experiments,
  });
  let service: Service | ServiceV2 | undefined;
  let isEditorX: any;
  let prevSettings = settings;

  return {
    async pageReady() {
      const { platformAPIs, wixCodeApi } = controllerConfig;

      if (shouldRedirectToCalendarPage(controllerConfig.wixCodeApi)) {
        const serviceSlug = await getServiceSlug({
          wixCodeApi,
          pageName: SERVICE_PAGE_NAME,
          useCustomUrlSegments: experiments.enabled(
            'specs.bookings.useCustomUrlSegmentsForSlug',
          ),
        });

        navigateToBookingsCalendarPage(wixCodeApi, platformAPIs, {
          serviceSlugOrBasket: serviceSlug,
        });

        setProps({
          isRedirectingToCalendar: true,
        });
        return;
      }

      const isBookingCalendarInstalled = await isBookingCalendarInstalledUtils(
        wixCodeApi,
      );

      const isBookingFormInstalled = await isBookingFormInstalledUtils(
        wixCodeApi,
      );

      const initSlotIntegration = (serviceId?: string) => {
        const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
        const slot1$w = slotAPIFactory.getSlotAPI('slot1');
        if (serviceId) {
          slot1$w.bookingsServiceId = serviceId;
        }
      };

      service = await initializeWidget({
        flowAPI,
        isBookingCalendarInstalled,
        isBookingFormInstalled,
        settings,
      });

      wixCodeApi.location.onChange(async () => {
        await initializeWidget({
          flowAPI,
          isBookingCalendarInstalled,
          isBookingFormInstalled,
          settings,
        });
      });

      initSlotIntegration(service?.id!);
    },
    updateConfig($w, newConfig) {
      const updatedPublicData = newConfig.publicData.COMPONENT || {};
      const updatedSettings = getSettingsValues(
        updatedPublicData,
        settingsParams,
        { experiments },
      );
      if (settings.scheduleDays !== updatedSettings.scheduleDays) {
        const dummyBusinessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: flowAPI.environment.language,
        };

        const scheduleViewModel = dummySchedulingViewModel({
          t,
          businessInfo: dummyBusinessInfo,
          scheduleDays: updatedSettings.scheduleDays,
        });

        setProps({
          scheduleViewModel,
        });
      }
      if (
        updatedSettings.displayNumberOfSpots !==
        prevSettings.displayNumberOfSpots
      ) {
        const courseAvailability =
          (updatedSettings.courseAvailability &&
            (updatedSettings.displayNumberOfSpots
              ? CourseAvailabilityDisplay.NUMBER_OF_SPOTS
              : CourseAvailabilityDisplay.AVAILABILITY)) ||
          undefined;
        const viewModel = dummyViewModelFactory({
          t,
          isEditorX,
          courseAvailability,
        });
        setProps({
          viewModel,
        });
      }
      prevSettings = updatedSettings;
    },
  };
};

export default createController;
