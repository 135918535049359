import { ControllerFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { ServicesCatalogServer } from '@wix/ambassador-services-catalog-server/http';
import { ScheduleServer } from '@wix/ambassador-schedule-server/http';
import {
  ListSchedulesRequest,
  Schedule,
} from '@wix/ambassador-schedule-server/types';
import {
  getInstance,
  getServerBaseUrl,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const SCHEDULER_SERVER_URL = '_api/schedule-reader-server';
export class BookingsApi {
  private httpClient: IHttpClient;
  private flowAPI: ControllerFlowAPI;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private scheduleServer: ReturnType<typeof ScheduleServer>;
  private authorization: string;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    const baseUrl = getServerBaseUrl({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });
    this.httpClient = flowAPI.httpClient;
    this.flowAPI = flowAPI;
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.authorization = getInstance({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });
    this.scheduleServer = ScheduleServer(`${baseUrl}${SCHEDULER_SERVER_URL}`);
  }

  public async queryServicesBySlug({
    serviceSlug,
  }: {
    serviceSlug: string;
  }): Promise<Service> {
    const { isPreview } = this.flowAPI.environment;
    const filter = {
      hidden: { $eq: false },
      'supportedSlugs.name':
        serviceSlug || !isPreview ? serviceSlug : undefined,
    };
    const res = await this.httpClient.request(
      queryServices({
        query: {
          filter,
        },
      }),
    );
    return (res.data.services && res.data.services[0])!;
  }

  public async getBusinessInfo() {
    const businessInfoService = this.catalogServer.BusinessCatalog();
    return businessInfoService({
      Authorization: this.authorization,
    }).get({});
  }

  public async listSchedule(scheduleOwnerId: string): Promise<Schedule> {
    const listSchedulesRequest: ListSchedulesRequest = {
      scheduleOwnerIds: [scheduleOwnerId],
      includeTotalNumberOfParticipants: this.flowAPI.experiments.enabled(
        'specs.bookings.availabilityIncludeTotalNumberOfParticipants',
      ),
    };
    const scheduleService = this.scheduleServer.Schedules();
    const schedulesResponse = await scheduleService({
      Authorization: this.authorization,
    }).list(listSchedulesRequest);
    return schedulesResponse?.schedules?.[0]!;
  }
}
