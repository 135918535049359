import {
  Location,
  LocationType,
} from '@wix/ambassador-bookings-services-v2-service/types';

export class LocationMapper {
  constructor(private clientLocationText: string) {}

  public text({
    serviceLocation,
    useBusinessName = false,
  }: {
    serviceLocation: Location | undefined;
    useBusinessName?: boolean;
  }): string {
    switch (serviceLocation?.type) {
      case LocationType.CUSTOM:
        return serviceLocation?.custom?.address?.formattedAddress as string;
      case LocationType.CUSTOMER:
        return this.clientLocationText;
      case LocationType.BUSINESS:
        return useBusinessName ? serviceLocation?.business?.name! : '';
      default:
        return '';
    }
  }
}
